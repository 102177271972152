import { Toolbar } from '@mui/material';
import { styled } from '@mui/system';

export const Label = styled('label')(({ theme }) => ({
    borderWidth: 1,
    borderBottomWidth: 1,
    borderStyle: 'dashed',
    borderColor: theme.palette.divider,
    width: '100%',
    display: 'flex',
    borderRadius: theme.spacing(1.25),
    cursor: 'pointer',
    padding: `${theme.spacing(5.25)} ${theme.spacing(12.5)}`
})
);