
import Grid from '@mui/material/Grid';
import { styled } from '@mui/system';

export const ContainerDirection = styled(Grid)(() => ({
    width: '22.3125rem',
    height: 'max-content',
    backgroundColor: '#FFFFFF',
    borderRadius: '1.5rem',
    position: 'relative'
})
);
export const ImageDirection = styled('img')(() => ({
    borderRadius: '12px 12px 0 0',
    height: '11.8125rem',
    width: '100%',
    objectFit: 'contain',
}))