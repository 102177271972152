
import { PageHeader, TabPanel } from 'components';
import { CertainTopicList } from 'components/CertainTopicList';
import { EditForm } from 'components/EditForm';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const CertainTopic = function () {
    const [open, setOpen] = React.useState(false);
    const { pathname } = useLocation()
    const navigate = useNavigate();
    const handleClose = () => {
        setOpen(false);
    };
    const headerButtonClickHandler = () => {
        navigate(`${pathname}/edit`)
    };
    return <>

        <PageHeader
            title='Новая тема'
            buttonText="Редактировать"
            buttonOnClick={headerButtonClickHandler}
            variant='outlined'
            back
        />
        <TabPanel height='100%' id={pathname} value={`${pathname}`} key={pathname}>
            <CertainTopicList />
        </TabPanel>
    </>
}