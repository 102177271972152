import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { FormFields } from 'components';
import { useCreateUserMutation } from 'core/services/users';
import { ICreateUser } from 'core/models';
import { createUsersFields } from './fields';

interface ICreateUserForm {
  handleClose: () => void;
}

export const CreateUserForm: FC<ICreateUserForm> = ({ handleClose }) => {
  const { control, handleSubmit } = useForm();
  const [createUser, { isLoading, isError }] = useCreateUserMutation();

  const onSubmit = (fields: Partial<ICreateUser>) => {
    console.log(fields);
    createUser({...fields, "company_id": 1, "first_name": 'IVAN',"last_name": "IVANOV", "middle_name": "IVANOVICH" });
  };
  
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle>Новый пользователь</DialogTitle>
      <DialogContent dividers>
        <FormFields fields={createUsersFields} control={control} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Отмена
        </Button>
        {isError && <Typography color="error">Ошибка</Typography>}
        <Button variant="contained" type="submit" disabled={isLoading} autoFocus>
          Создать
        </Button>
      </DialogActions>
    </form>
  );
};
