import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { ContainerDirection, ImageDirection } from './style';
import { IProps } from './types';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useLocation, useNavigate } from 'react-router-dom';
export const Direction: React.FC<IProps> = function ({ id, img, creator, title, amountDirections, amountTopic }) {
    const { pathname } = useLocation()
    const navigate = useNavigate();
    const openDirection = () => navigate(`${pathname}/${id}/topics`)
    return <ContainerDirection onClick={openDirection}>
        <div style={{
            cursor: 'pointer'
        }}>
        <ImageDirection src={img} alt='Фото направления' />
        <Grid sx={{
            padding: '1.5rem',
        }}>
            <Typography sx={{
                color: 'text.lightGray'
            }}>
                {creator}
            </Typography>
            <MoreVertIcon sx={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                color: 'text.lightGray'
            }} />
            <Typography sx={{
                paddingTop: '10px',
                paddingBottom: '24px'
            }} variant='h2'>
                {title}
            </Typography>
            <Box display='flex'>
                <Typography variant='h5' sx={{
                    color: 'text.lightGray',
                }}>
                    {amountDirections} Тем
                </Typography>
                <Typography variant='h5' sx={{
                    color: 'text.lightGray',
                }} paddingLeft={6}>
                    {amountTopic} Теста
                </Typography>
            </Box>
        </Grid>
        </div>

    </ContainerDirection>
}