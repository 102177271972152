import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { DialogContent, DialogActions, Button, Box } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import ButtonBase from '@mui/material/ButtonBase';
import DialogTitle from '@mui/material/DialogTitle';
import { FormFields } from 'components/FormFields';
import { createFields } from './fields';
interface ICreateQuizzeForm {
    handleClose: () => void;
}

export const CreateQuizzeForm: FC<ICreateQuizzeForm> = ({ handleClose }) => {
    const { control, handleSubmit } = useForm();
    const onSubmit = () => console.log('Handle Create Direction')
    return (
        <Box sx={{
            alignSelf: 'start',
            backgroundColor: '#FFF',
            borderRadius: '0.625rem'
        }}>

            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>О теме</DialogTitle>

                <DialogContent>
                    <FormFields fields={createFields} control={control} />
                </DialogContent>
                <DialogActions>

                    <Button variant="contained" type="submit" autoFocus>
                        Добавить вопрос +
                    </Button>
                </DialogActions>
            </form>
        </Box>
    );
};