import React, { memo, useState } from "react";
import { changeStateAuthForm } from "core/store/slices/auth";
import { useSendCodeRecoveryMutation } from "core/services/auth";
import { useAppDispatch } from "core/hooks/typed.hooks";
import { EStateAuthForm } from "core/constants/auth.constants";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { CodeReset } from "../CodeReset";

/**
 * @description Форма проверки кода восстановления
 */
export const AuthFormCodeReset: React.FC = memo(function () {
    const dispatch = useAppDispatch();
    const [sendCode, { error, isLoading }] = useSendCodeRecoveryMutation();
    // State Component
    const [otp, setOtp] = useState<Array<string>>(new Array(4).fill(""));
    /**
     * @return {void}
     * @description Функция отправки кода восстановления на сервер
     */
    const runSendCode = async function () {
        try {
            const payload = await sendCode({
                code: otp.join('')
            }).unwrap();
            if (payload) {
                dispatch(changeStateAuthForm(EStateAuthForm.UPDATE_PASSWORD));
                setOtp(new Array(4).fill(''));
            }
        } catch (error) {
            console.error('rejected', error);
            setOtp(new Array(4).fill(''));
        }
    }
    return (
        <>
            <Typography
                variant='h2'
                fontWeight={400}
                marginBottom="24px"
            >
                Введите код из письма
            </Typography>
            <Typography
                variant='subtitle2'
                sx={{
                    marginBottom: '24px',
                    color: '#929EAF'
                }}
            >
                Если вы не нашли письма, проверьте папку “Спам”
            </Typography>
            <CodeReset otp={otp} setOtp={setOtp} />
            {error && <Typography
                variant='subtitle2'
                sx={{
                    marginBottom: '24px',
                    color: '#FF3B30'
                }}
            >
                {(error as { status: number; data: string }).data}
            </Typography>}
            <Button sx={{
                marginTop: '34px',
                borderRadius: '12px',
                textTransform: 'initial',
                width: '265px'
            }}
                variant='contained'
                onClick={runSendCode}
                disabled={isLoading}
            >
                Продолжить
            </Button>
        </>
    );
});