import React, { FC } from 'react';
import { Tabs, Tab } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { IMenuLinks } from 'core/config/menuLinks';
import { PageTabsWrapper } from './style';

interface IPageTabs {
  path: string;
  tabs: IMenuLinks[];
}

export const PageTabs: FC<IPageTabs> = ({ path, tabs }) => {
  const navigate = useNavigate();
  const { tab, directionId, tabDirection } = useParams();
  const tabsChangeHandle = (_: any, newTab: string) => {
    navigate(newTab);
  };

  return (
    <PageTabsWrapper>
      {
        directionId === undefined && <Tabs value={`/${path}/${tab}`} onChange={tabsChangeHandle}>
          {tabs.map(({ link, title }) => (
            <Tab sx={{
              textTransform: 'capitalize'
            }} label={title} value={link} key={link} />
          ))}
        </Tabs>
      }
      {
        directionId !== undefined && <Tabs value={`/${path}/${tab}/${directionId}/${tabDirection}`} onChange={tabsChangeHandle}>
          {tabs.map(({ link, title }) => (
            <Tab sx={{
              textTransform: 'capitalize'
            }} label={title} value={link} key={link} />
          ))}
        </Tabs>
      }
    </PageTabsWrapper>
  );
};
