import { createTheme } from "@mui/material";

declare module '@mui/material' {
  interface TypeBackground {
    main: string;
    lightGray: string;
  }

  interface TypeText {
    lightGray: string;
  }

  interface Palette {
    system: string;
  }

  interface PaletteOptions {
    system?: string;
  }
}

export const defaultTheme = createTheme({
  components: {
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: "space-between",
          padding: '1rem 1.5rem',
        },
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          paddingLeft: '6.25rem',
          paddingRight: '6.25rem',
        }
      },
      defaultProps: {
        variant: 'dense',
        disableGutters: true,
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: 'separate',
          borderSpacing: '0 0.5rem',
        }
      }
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          backgroundColor: '#E5E5E9',
          borderRadius: '10px'
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          gap: '1.5rem',
          backgroundColor: '#ffffff',
          borderRadius: '8px',
          '& td:first-child, & th:first-child': {
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
          },
          '& td:last-child, & th:last-child': {
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
          },
          '&:hover': {
            cursor: 'pointer',
          }
        },
        head: {
          backgroundColor: '#E8E9ED',
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontSize: '1.25rem',
          color: '#5D6A7E'
        },
        sizeMedium: {
          padding: '1.5rem',
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          textTransform: 'unset',
        },
        contained: {
          background: "main",
          fontSize: '1rem',
          lineHeight: '1.3rem',
          padding: '0.59375rem 1.5rem'
        },
        outlined: {
          borderColor: '#E2E2E4',
          color: '#1C2026',
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          background: '#F6F7F9',
          color: '#929EAF',
        },
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          borderRadius: 8,
          padding: '0.875rem 1rem',
          gap: '0.875rem',
          color: 'white',
          margin: '0.875rem 0',
          '&.Mui-selected': {
            backgroundColor: 'rgba(255, 255, 255, 0.15)',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.15)',
            }
          },
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.15)',
          }
        },
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'unset',
          width: 'auto',
          color: 'white',
        },
      }
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: '0.875rem',
        },
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '&:not(:last-child)': {
            marginBottom: '1.5rem'
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          borderColor: '#E2E2E4',
        }
      }
    }
  },
  typography: {
    fontSize: 16,
    fontFamily: ['Rubik', 'sans-serif'].join(','),
    h1: {
      fontSize: '2.625rem',
    },
    h2: {
      fontSize: '1.5rem'
    },
    subtitle1: {
      fontSize: '1.125rem',
    },
    button: {
      fontSize: '1.125rem'
    }
  },
  palette: {
    primary: {
      main: '#235EE7',
    },
    text: {
      primary: '#1C2026',
      secondary: '#5D6A7E',
      lightGray: '#929EAF',
    },
    background: {
      main: '#141B29',
      lightGray: '#F6F7F9',
    },
    divider: '#E8E9ED',
  },
});