import React, { useState, Fragment } from 'react';
import { Grid, Typography } from '@mui/material';
import { StatsForm } from 'components/StatsForm';
import Dialog from '@mui/material/Dialog';
import { CreateQuizzeForm } from 'components/CreateQuizzeForm';
import { useParams } from 'react-router-dom';
import { useGetOneTopicsQuery } from 'core/services/topics';
export const CertainTopicList = function () {
    const [open, setOpen] = React.useState(false);
    const { idTopic } = useParams();
    console.log(idTopic);
    const { data } = useGetOneTopicsQuery(idTopic as unknown as number);
    console.log(data);
    const handleClose = () => {
        setOpen(false);
    };
    const headerButtonClickHandler = () => {
        setOpen(true);
    };
    return <>
        {data && data.questions.length === 0 &&
            <Grid sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0 auto'
            }}>
                <Typography sx={{
                    textAlign: 'center',
                    color: 'text.lightGray'
                }}
                >
                    Список вопросов пуст
                </Typography>
            </Grid>
        }
        {data && <Grid sx={{
            alignSelf: 'start',
            gap: '24px 16px',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            marginRight: 5
        }}>
            {data.questions.map((question, index) => <Fragment key={question.id}>
                <Grid sx={{
                    backgroundColor: '#FFF',
                    borderRadius: '0.625rem',
                    padding: '1rem 0 1rem 1.5rem',
                    cursor: 'pointer'
                }} display='flex' flexDirection='column'>
                    <Typography variant='h2'>
                        {question.text}
                    </Typography>
                </Grid>
            </Fragment>)}
        </Grid>}
        <>
            <CreateQuizzeForm handleClose={handleClose} />
            <Dialog sx={{
                '& div': {
                    overflow: 'hidden'
                }
            }} open={open} onClose={handleClose}>
                <Typography>В разработке</Typography>
                {/* <CreateQuizzeForm handleClose={handleClose} /> */}
            </Dialog>
        </>
    </>
}