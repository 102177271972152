import { Grid } from '@mui/material';
import { styled } from '@mui/system';

export const FormContainer = styled(Grid)(({ theme }) => ({
  overflow: 'auto',
  backgroundColor: '#fff',
  borderRadius: '10px',
  gap: theme.spacing(3),
  padding: theme.spacing(3),
}));
export const FormContainerSize = styled(Grid)(({ theme }) => ({
  height: '100%',
  overflow: 'auto',
  backgroundColor: '#fff',
  borderRadius: '10px',
  gap: theme.spacing(3),
  padding: theme.spacing(3),
}));
