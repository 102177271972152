// import { TestPage } from "pages/TestPage";
import { UserPage, UsersPage } from "pages";
import { CertainDirection } from "pages/certain-direction";
import { CertainTopic } from "pages/certain-topic";
import { Directions } from "pages/directions";
import { Edit } from "pages/edit";
import { IRouteList } from "./types";
import { Settings } from "components/Settings";
import { CurrentTesting } from "components/CurrentTesting";

// Список маршрутов приложения
export const routes: IRouteList = [
    {
        element: CertainDirection,
        path: '/test/:tab/:directionId/:tabDirection'
    },
    {
        element: Edit,
        path: '/test/:tab/:directionId/:tabDirection/edit'
    },
    {
        element: Directions,
        path: '/test/:tab'
    },
    {
        element: UsersPage,
        path: '/users/:tab'
    },
    {
        element: UserPage,
        path: '/users/:tab/:userId'
    },
    {
        element: CertainTopic,
        path: '/test/:tab/:directionId/:tabDirection/:idTopic'
    },
    {
        element: Edit,
        path: '/test/:tab/:directionId/:tabDirection/:idTopic/edit'
    },
    {
        element: Settings,
        path: '/settings'
    },
    {
        element: CurrentTesting,
        path: '/test/:tab/:user_quiz_id'
    }
]