import { styled } from '@mui/system';

export const LayoutWrapper = styled('section')({
  display: "grid",
  gridTemplateColumns: '220px auto',
  maxWidth: "100vw",
  height: '100vh',
  overflow: 'hidden',
});

export const Main = styled('main')({
  width: '100%',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
})