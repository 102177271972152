import React from 'react';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import iconFileUpload from '../../assets/img/fileupload.svg';
import Grid from '@mui/material/Grid';
import { Label } from './style';
interface IProps {
    label?: string;
}
// @ts-ignore
export const FileUpload: React.FC<IProps> = function ({ label, onChange }) {
    return <DialogContent>
        <Typography variant='body1' sx={{
            color: 'text.secondary',
        }}>{`${label || 'Обложка'}`}</Typography>
        <Label htmlFor='upload-direction-img'>
            <Grid textAlign='center'>
                <img src={iconFileUpload} alt="icon fileupload" />
                <Typography variant='subtitle1' sx={{
                    color: 'text.secondary'
                }}>
                    Нажмите чтобы загрузить или перетащите логотип
                </Typography>
            </Grid>
        </Label>
        <TextField
            sx={{
                display: 'none'
            }}
            onChange={onChange}
            type='file'
            id='upload-direction-img'
        />
    </DialogContent>
}