import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import { PageTabs, TabPanel } from 'components';
import { directionsTabs } from 'core/config/menuLinks';
export const DirectionsContent = function () {
    const { tab } = useParams();
    return <>
        {/* <PageTabs path='test' tabs={directionsTabs} /> */}
        {
            directionsTabs.map(({ id, Component }) => (
                <TabPanel height='100%' id={id} value={tab} key={id}>
                    {Component}
                </TabPanel>
            ))
        }
    </>
}