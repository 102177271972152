import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useGetUsersQuery } from 'core/services/users';
import { getRoleName } from 'core/utils';

export const Employees = () => {
  const navigate = useNavigate();
  const { data } = useGetUsersQuery();

  const rowClickHandler = (userId: number) => {
    navigate(`${userId}`, { replace: true });
  };

  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
          <TableRow>
            <TableCell>Фамилия Имя</TableCell>
            <TableCell>Роль</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Регистрация</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data ? (
            data.map(({ first_name, last_name, id, email, role_id }) => (
              <TableRow
                key={id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                onClick={() => rowClickHandler(id)}>
                <TableCell>{`${last_name} ${first_name}`}</TableCell>
                <TableCell>{getRoleName(role_id)}</TableCell>
                <TableCell>{email}</TableCell>
                <TableCell>Нет данных</TableCell>
              </TableRow>
            ))
          ) : (
            <Typography>Нет данных</Typography>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
