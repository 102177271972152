import React from "react";
import { EStateAuthForm } from "core/constants/auth.constants";
import { useAppSelector } from "core/hooks/typed.hooks";
import { AuthFormCodeReset } from "./subcomponents/AuthFormCodeReset";
import { AuthFormResetPassword } from "./subcomponents/AuthFormResetPassword";
import { AuthFormUpdatePassword } from "./subcomponents/AuthFormUpdatePassword";
import { AuthFormLogin } from "./subcomponents/AuthFormLogin";
import { ContainerAuth } from "./style";


// Основной компонент формы аутентификации
const AuthFormWrapper: React.FC = function () {
    // Switch между вариантами формы авторизации
    const stateForm = useAppSelector(store => store.authReducer.stateAuthForm);

    return (
        <ContainerAuth>
            {stateForm === EStateAuthForm.LOGIN && <AuthFormLogin />}
            {stateForm === EStateAuthForm.RECOVERY && <AuthFormResetPassword />}
            {stateForm === EStateAuthForm.SEND_CODE_RECOVERY && <AuthFormCodeReset />}
            {stateForm === EStateAuthForm.UPDATE_PASSWORD && <AuthFormUpdatePassword />}
        </ContainerAuth>
    )
}
export { AuthFormWrapper };