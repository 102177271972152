import React from 'react';
import { Header, Navigator } from 'components';
import { LayoutWrapper, Main } from 'components/Layout/style';
import { Outlet } from 'react-router-dom';

export const Layout = () => {
  return (
    <LayoutWrapper>
      <Navigator />
      <Main>
        <Header />
        <Outlet />
      </Main>
    </LayoutWrapper>
  );
};
