import { Typography } from '@mui/material';

export const Logo = () => {
  return (
    <Typography
      variant="h1"
      component="h2"
      fontWeight="bold"
      color="divider"
      textAlign="center"
      mt={4}
      mb={6}>
      Learn{' '}
      <Typography variant="h1" component="span" color="primary" fontWeight="bold">
        I
      </Typography>
      T
    </Typography>
  );
};
