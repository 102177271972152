import React from 'react';
import { CreateUserForm, PageHeader, Users } from 'components';
import { Dialog, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

export const UsersPage = () => {
  const { userId } = useParams();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const headerButtonClickHandler = () => {
    setOpen(true);
  };

  return (
    <>
      <PageHeader
        title="Участники пространства"
        description="Здесь находится список всех участников вашего рабочего пространства, а также карточки кандидатов и их тестирований"
        buttonText="Создать участника"
        buttonOnClick={headerButtonClickHandler}
      />
      {userId ? (
        <Typography>{userId}</Typography>
      ) : (
        <>
          <Users />
          <Dialog open={open} onClose={handleClose}>
            <CreateUserForm handleClose={handleClose} />
          </Dialog>
        </>
      )}
    </>
  );
};
