import React from 'react';
import { Grid, Toolbar, Typography, Link } from '@mui/material';
import { UserEditForm } from './UserEditForm';
import { CuratorsForm } from 'components/CuratorsForm';
import { useGetUserQuery } from 'core/services/users';
import { useParams } from 'react-router-dom';
import { FormContainerSize } from 'components/Containers';

export const User = () => {
  const { userId, tab } = useParams();
  const isEmployees = tab === 'employees' && userId;
  const { data, isLoading } = useGetUserQuery(+(userId || ''));
  console.log(data?.quizzes);
  return (
    <Toolbar>
      <Grid container spacing={3}>
        <Grid xs={3} item>
          {data && <UserEditForm data={data} isLoading={isLoading} />}
          {
            data && <Grid xs={12}>
              <CuratorsForm data={data} />
            </Grid>
          }
        </Grid>
        <Grid xs={9} item>
          {!isEmployees && <FormContainerSize>
            <Typography variant="h2">
              Тесты кандидата
            </Typography>
            {(data && data.quizzes.length === 0)
              &&
              <Grid container flexDirection={"column"} alignItems={"center"} justifyContent={"center"} height={'100%'} sx={{overflow: 'hidden !important'}}>
                <Typography>
                  У кандидата пока нет тестов для прохождения.
                </Typography>
                <Typography>
                  Создайте новый тест и отправьте ему ссылку на прохождение тестирования.
                </Typography>
                <Link sx={{
                  cursor: 'pointer'
                }} underline="none">
                  Назначить тест
                </Link>
              </Grid>
            }
            {
              (data && data.quizzes.length !== 0)
              &&
              <Grid container flexDirection={"column"} alignItems={"center"} justifyContent={"center"} height={'100%'} overflow={"hidden !important"}>
               
              </Grid>
            }
          </FormContainerSize>}
        </Grid>
      </Grid>
    </Toolbar>
  );
};
