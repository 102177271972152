import React, { ChangeEvent, Dispatch, KeyboardEvent, SetStateAction, useEffect, useRef, useState } from "react";
import { Grid, TextField } from "@mui/material";

let currentOTPIndex = 0;
// @description Компонент кода восстановления пароля
// @props {Array<string>} otp - количество контролов
// @props {Dispatch<SetStateaction<Array<string>>>} setOtp - обработчик контролов
const CodeReset: React.FC<{
    otp: Array<string>;
    setOtp: Dispatch<SetStateAction<Array<string>>>
}> = function ({ otp, setOtp }) {

    const [activeOTPIndex, setActiveOTPIndex] = useState(0);
    const inputRef = useRef(null);
    // @description Функция обработчик контролов с возможностью изменения фокуса на следующий контрол
    // @param {ChangeEvent<HTMLInputElement | HTMLTextAreaElement>} e - событие контрола
    // @param {number} index - позиция контрола
    // @return {void}
    const handleOnChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        if (otp[index]) return;
        const { value } = e.target;
        const newOTP = [...otp];
        newOTP[currentOTPIndex] = value.substring(value.length - 1);
        if (!value) {
            setActiveOTPIndex(currentOTPIndex - 1);
        } else {
            setActiveOTPIndex(currentOTPIndex + 1);
        }
        setOtp(newOTP);
    };
    // @description Функция обработчик контролов с возможностью изменения фокуса на предыдущий контрол
    // @param {ChangeEvent<KeyboardEvent<HTMLDivElement>} e - событие контрола
    // @param {number} index - позиция контрола
    // @return {void}
    const handleOnKeyDown = (e: KeyboardEvent<HTMLDivElement>, index: number) => {
        if (e.key === "Backspace" && otp[index] === "" && index === 0) return;
        currentOTPIndex = index;
        if (e.key === "Backspace") {
            const mokeOTP = [...otp];
            mokeOTP[index] = "";
            setOtp(mokeOTP);
            setActiveOTPIndex(currentOTPIndex - 1);
        }
    };
    // @description изменение фокуса контрола в зависимости от активной позиции контрола
    useEffect(() => {
        if (inputRef.current) (inputRef.current as HTMLInputElement | HTMLTextAreaElement).focus();
    }, [activeOTPIndex]);
    return (
        <Grid sx={{
            gap: 4,
            width: 'max-content',
            display: 'flex'
        }}>
            {otp.map((_, index) => {
                return (
                    <React.Fragment key={index}>
                        <TextField
                            size="small"
                            sx={{
                                "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                                {
                                    WebkitAppearance: "none",
                                    margin: 0,
                                },
                                "input[type=number]": {
                                    MozAppearance: "textfield",
                                },
                                '& input': {
                                    textAlign: 'center',
                                },
                                '& fieldset': {
                                    borderRadius: 2.5,
                                },
                                width: 44,

                            }}

                            inputRef={index === activeOTPIndex ? inputRef : null}
                            onChange={(e) => handleOnChange(e, index)}
                            placeholder="_"
                            type="number"
                            onKeyDown={(e) => handleOnKeyDown(e, index)}
                            className=""
                            value={otp[index]}
                        />
                    </React.Fragment>
                );
            })}
        </Grid>
    )
}

export { CodeReset };