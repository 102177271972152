import React from 'react';
import { useParams } from 'react-router-dom';
import { PageTabs, TabPanel } from 'components';
import { usersTabs } from 'core/config/menuLinks';

export const Users = () => {
  const { tab } = useParams();
  return (
    <>
      <PageTabs path="users" tabs={usersTabs} />
      {usersTabs.map(({ id, Component }) => (
        <TabPanel id={id} value={tab} key={id}>
          {Component}
        </TabPanel>
      ))}
    </>
  );
};
