import React, { useState, Fragment } from 'react';
import { Grid, Typography } from '@mui/material';
import { StatsForm } from 'components/StatsForm';
import Dialog from '@mui/material/Dialog';
import { CreateForm } from 'components/CreateTopicForm';
import { Topic } from 'components/Topic';
import { useParams } from 'react-router-dom';
import { useGetTopicsQuery } from 'core/services/topics';
export const CertainDirectionList = function () {
    const [open, setOpen] = React.useState(false);
    const {directionId} = useParams();
    const {data} = useGetTopicsQuery(directionId as unknown as number);
    const handleClose = () => {
        setOpen(false);
    };
    const headerButtonClickHandler = () => {
        setOpen(true);
    };
    return <>
        {data && data.data.length !== 0 && <Grid sx={{
            alignSelf: 'start',
            gap: '24px 16px',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            marginRight: 5
        }}>

            {data.data.map((topic, index) => <Fragment key={index}>
                <Topic idTopic={topic.id} description={topic.description} title={topic.name} size_questions={topic.questions.length} />
            </Fragment>)}

        </Grid>
        }
        {data && data.data.length === 0 &&

            <Grid sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0 auto'
            }}>
                <Typography sx={{
                    textAlign: 'center',
                    color: 'text.lightGray'
                }}
                >
                    Список тем пуст.<br />
                    Создайте первую тему с вопросами, чтобы начать тестирование кандидатов
                </Typography>
            </Grid>
        }

        <>
            <StatsForm buttonOnClick={headerButtonClickHandler} />
            <Dialog sx={{
                '& div': {
                    overflow: 'hidden'
                }
            }} open={open} onClose={handleClose}>
                <CreateForm handleClose={handleClose} />
            </Dialog>
        </>
    </>
}