import { AuthFormWrapper } from 'components/Auth';
import React from 'react';
import { LayoutWrapper } from "components/Layout/style";
import { LayoutAuth, Background, WrapperAuth, WrapperLogo } from './style';
import { Typography } from '@mui/material';
//@description Страница авторизации пользователя
const Auth: React.FC = function () {

    return (
        <LayoutWrapper>
            <LayoutAuth>
                <Background>
                    <WrapperLogo>
                        <Typography
                            variant='h1'
                            sx={{
                                color: '#FFFFFF',
                                fontSize: '8.875rem',
                                fontWeight: 600

                            }}>
                            LearnIT
                        </Typography>
                        <Typography
                            variant='body1'
                            sx={{
                                color: '#FFFFFF',
                                fontSize: '4rem',
                                fontWeight: 400,
                                maxWidth: '470px',
                                opacity: 0.7
                            }}
                        >
                            Тестирование кандидатов
                            для вашей компании
                        </Typography>
                    </WrapperLogo>
                </Background>
                <WrapperAuth>
                    <AuthFormWrapper />
                </WrapperAuth>
            </LayoutAuth>
        </LayoutWrapper>
    )
}
export { Auth };