import { ReactElement } from 'react';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { Candidate } from 'components/Users/Candidate';
import { Employees } from 'components/Users/Employees';
import { DirectionList } from 'components/DirectionList';
import { CertainDirectionList } from 'components/CertainDirectionList';
import { Testing } from 'components/Testing';

export interface IMenuLinks {
  id: string;
  title: string;
  link?: string;
  icon: ReactElement;
  Component?: ReactElement;
}
export const directionTabs = (directionId: number | string) => {
  return [
    {
      id: `${directionId}/topics`,
      title: 'Темы',
      link: `/test/directions/${directionId}/topics`,
      icon: <PeopleAltOutlinedIcon fontSize="small" />,
      Component: <CertainDirectionList />,
    },
    {
      id: `${directionId}/quizzes`,
      title: 'Тесты',
      link: `/test/directions/${directionId}/quizzes`,
      icon: <PeopleAltOutlinedIcon fontSize="small" />,
      Component: <div>В разработке</div>,
    },
  ];
}
export const directionsTabs = [
  {
    id: 'directions',
    title: 'Направления',
    link: '/test/directions',
    icon: <PeopleAltOutlinedIcon fontSize="small" />,
    Component: <DirectionList />,
  },
  {
    id: 'check',
    title: 'Проверка',
    link: '/test/check',
    icon: <PeopleAltOutlinedIcon fontSize="small" />,
    Component: <Testing />,
  },
];
export const usersTabs = [
  {
    id: 'candidate',
    title: 'Кандидаты',
    link: '/users/candidate',
    icon: <PeopleAltOutlinedIcon fontSize="small" />,
    Component: <Candidate />,
  },
  {
    id: 'employees',
    title: 'Сотрудники',
    link: '/users/employees',
    icon: <PeopleAltOutlinedIcon fontSize="small" />,
    Component: <Employees />,
  },
];

export const menuLinks: IMenuLinks[] = [
  ...directionsTabs,
  ...usersTabs,
  {
    id: 'settings',
    icon: <SettingsOutlinedIcon fontSize="small" />,
    title: 'Настройки',
  },
];
