import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import { PageTabs, TabPanel } from 'components';
import { directionTabs } from 'core/config/menuLinks';
import { ITopics } from 'core/models/topics.models';

export const DirectionContent = function () {

    const { directionId, tabDirection } = useParams();
    const updateDirectionTabs = directionTabs(directionId as string);
    return <>
        <PageTabs path='test' tabs={updateDirectionTabs} />
        {
            updateDirectionTabs.map(({ id, Component }) => (
                <TabPanel height='100%' id={id} value={`${directionId}/${tabDirection}`} key={id}>
                    {Component}
                </TabPanel>
            ))
        }
    </>
}