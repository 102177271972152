import React from 'react';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { Toolbar, Grid, IconButton, Link, Tooltip } from '@mui/material';
import { useAppSelector } from 'core/hooks/typed.hooks';

export const Header = () => {
  const user = useAppSelector(store => store.userReducer.user);
  const isUserName = (user.role_id && user.role_id === 5) ? 'Суперадмин' : `${user.first_name} ${user.last_name}`;
  return (
    <Grid py={0.5}>
      <Toolbar>
        <Grid container columnSpacing={1} alignItems="center" justifyContent="end">
          <Grid item>
            <Tooltip title="Alerts • No alerts">
              <IconButton>
                <NotificationsNoneIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Link
              href="/settings"
              variant="body2"
              sx={{
                textDecoration: 'none',
              }}
              color="text.secondary"
              rel="noopener noreferrer"
              target="_blank">
                {isUserName}
            </Link>
          </Grid>
        </Grid>
      </Toolbar>
    </Grid>
  );
};
