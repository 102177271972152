import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
interface IProps {
    idTopic: number | string;
    title: string;
    description: string;
    size_questions: number;
}
export const Topic: React.FC<IProps> = function ({ idTopic, title, description, size_questions }) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    return <Grid onClick={() => navigate(`${pathname}/${idTopic}`)} sx={{
        backgroundColor: '#FFF',
        borderRadius: '0.625rem',
        padding: '1rem 0 1rem 1.5rem',
        cursor: 'pointer'
    }} display='flex' flexDirection='column'>
        <Typography variant='h2'>
            {title} ({size_questions})
        </Typography>
        <Typography sx={{
            color: 'text.lightGray'
        }}>
            {description}
        </Typography>
    </Grid>
}