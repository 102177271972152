import { FormContainer } from 'components/Containers';
import { FormFields } from 'components/FormFields';
import { curatorsField } from 'components/User/UserEditForm/fields';
import React, { useState } from 'react';
import { TextField, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { IUser } from 'core/models';
import { getRoleName } from 'core/utils';

export const CuratorsForm: React.FC<{data: IUser}> = function ({data}) {
    const { userId, tab } = useParams();
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const isEmployees = tab === 'employees' && userId;
    return <>
        {
            !isEmployees
            &&
            <div style={{marginTop: 25}}>
                <FormContainer>
                    <Typography variant="h2" mb={5}>Кураторы</Typography>
                    <TextField fullWidth label={'HR'} value={
                        data.role_id
                        ?
                        `@${getRoleName(data.role_id)}`
                        :
                        ''
                    } />
                    <TextField disabled fullWidth label={'Проверяющие'} value={
                        data.curators
                        ?
                        data.curators
                        :
                        'Не назначены'
                    } />
                </FormContainer>
            </div>
        }
    </>
}