import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { DialogContent, DialogActions, Button } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import ButtonBase from '@mui/material/ButtonBase';
import DialogTitle from '@mui/material/DialogTitle';
import { FormFields } from 'components/FormFields';
import { createFields } from './fields';
import { useParams } from 'react-router-dom';
import { useCreateTopicMutation } from 'core/services/topics';
interface ICreateForm {
    handleClose: () => void;
}

export const CreateForm: FC<ICreateForm> = ({ handleClose }) => {
    const { control, handleSubmit, getValues } = useForm();
    const {directionId} = useParams();
    const [createTopic, {data}] = useCreateTopicMutation();
    const onSubmit = async () => {
        const form = new FormData();
        const values = getValues();
        form.append('name', values.name_topic);
        form.append('description', values.description_topic);
        form.append('topic_category_id', directionId + '');
        createTopic(form)
    }
    return (
        <form>
            <DialogTitle display='flex' justifyContent='space-between'>
                Новая тема
                <ButtonBase onClick={onSubmit}>
                    <ClearIcon />
                </ButtonBase></DialogTitle>
            <DialogContent>
                <FormFields fields={createFields} control={control} />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleClose}>
                    Отмена
                </Button>
                {/* {isError && <Typography color="error">Ошибка</Typography>}
             <Button variant="contained" type="submit" disabled={isLoading} autoFocus>
               Создать
             </Button> */}
                <Button onClick={onSubmit} variant="contained" type="submit" autoFocus>
                    Создать
                </Button>
            </DialogActions>
        </form>
    );
};