import React, { Fragment } from 'react';
import { Typography, Grid } from "@mui/material";
import { Direction } from 'components/Direction/Direction';
import { useGetTopicsCategoryQuery } from 'core/services/topicsCategory';
import { host } from 'core/config/api';
import notFound from '../../assets/img/not_found.jpg';
interface ITree {
    value: null | number;
    left: null | ITree;
    right: null | ITree;
}

export const DirectionList = function () {
    const { data } = useGetTopicsCategoryQuery();
    console.log(data);
    return <>
        {data && data.data.length !== 0 && <Grid sx={{
            alignSelf: 'start',
            gap: '40px 24px',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around'
        }}>
            {data.data.map((direction) => <Fragment key={direction.id}>
                <Direction id={direction.id} img={direction.image ? `${direction.image}` : notFound} title={direction.name} creator='admin' amountDirections={direction.topics.length} amountTopic={0} />
            </Fragment>)}
        </Grid>
        }
        {data && data.data.length === 0 && <Grid sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto'
        }}>
            <Typography sx={{
                textAlign: 'center',
                color: 'text.lightGray'
            }}
            >
                Список направлений пуст.<br />
                Создайте первое направление, чтобы подготовить тест для кандидата
            </Typography>
        </Grid>
        }
    </>
}