import React from 'react';
import { PageHeader, TabPanel } from 'components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CertainTopicList } from 'components/CertainTopicList';
import { useGetTestQuery } from 'core/services/testing';
import { Grid } from '@mui/material';
export const CurrentTesting = () => {
    const { user_quiz_id } = useParams();
    const { pathname } = useLocation();
    const { data } = useGetTestQuery(user_quiz_id);
    console.log(data);
    const navigate = useNavigate();
    const headerButtonClickHandler = () => {
        navigate(`${pathname}/edit`)
    };
    return <>
        <PageHeader
            title={`Тестирование кандидата №`}
            buttonOnClick={headerButtonClickHandler}
            variant='outlined'
            back
        />
        <TabPanel height='100%' id={pathname} value={`${pathname}`} key={pathname}>
            <Grid alignSelf={"baseline"} container>
                <Grid gap={2}  alignSelf={"baseline"} container flexDirection={"column"}>
                    {data && Object.values(data.answer.data).map(answer => <Grid sx={{
                        backgroundColor: 'white',
                        borderRadius: '5px',
                    }} paddingLeft={4} paddingTop={2} paddingBottom={2} key={answer.question_id}>
                        {answer.text}
                    </Grid>)}
                </Grid>
                <Grid>
                    
                </Grid>
            </Grid>
        </TabPanel>

    </>
};