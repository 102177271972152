import React, { FC } from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Toolbar, Grid, Typography, Button, IconButton } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
interface IPageHeader {
  title: string;
  description?: string;
  buttonText?: string;
  buttonOnClick?: () => void;
  backLink?: string;
  variant?: 'outlined';
  back?: boolean;
}

export const PageHeader: FC<IPageHeader> = ({
  title,
  description,
  buttonText,
  buttonOnClick,
  backLink,
  variant,
  back
}) => {
  const navigate = useNavigate()
  return (
    <Grid py={2}>
      <Toolbar>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Grid container alignItems="center" mb={1.4375} gap={1.5}>
              {backLink && (
                <IconButton component={Link} to={backLink}>
                  <ArrowBackIosNewIcon />
                </IconButton>
              )}
              <Typography variant="h1">
                {back && <Button
                  onClick={() => navigate(-1)}
                  sx={{
                    marginRight: '0.5rem'
                  }} variant='outlined'>
                  <ArrowBackIosNewTwoToneIcon />
                </Button>}
                {title}
              </Typography>
            </Grid>
            {description && (
              <Typography variant="body2" color="text.lightGray" maxWidth={500}>
                {description}
              </Typography>
            )}
          </Grid>
          {buttonText && (
            <Grid item>
              <Button variant={`${variant || 'contained'}`} onClick={buttonOnClick}>
                {buttonText}
              </Button>
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </Grid>
  );
};
