import React, { memo, useState } from "react";
import { changeStateAuthForm } from "core/store/slices/auth";
import { useUpdatePasswordMutation } from "core/services/auth";
import { useAppDispatch } from "core/hooks/typed.hooks";
import { EStateAuthForm } from "core/constants/auth.constants";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { useCompare } from "core/hooks/useCompare";


//@description Форма обновления пароля
export const AuthFormUpdatePassword: React.FC = memo(function () {
    // @description API отправки обновленного пароля на сервер
    const dispatch = useAppDispatch();
    const [updatePassword, { error, isLoading }] = useUpdatePasswordMutation();
    // @description State Component
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [compare, comparator] = useCompare();
    // @return {void}
    // @description Функция отправки обновленного пароля на сервер
    const runUpdatePassword = async function () {
        try {
            if (!comparator(password, repeatPassword)) return;
            const payload = await updatePassword({
                old_password: password,
                new_password: repeatPassword
            }).unwrap();
            if (payload) {
                dispatch(changeStateAuthForm(EStateAuthForm.LOGIN));
                setPassword('');
                setRepeatPassword('');
            }
        } catch (error) {
            console.error('rejected', error);
            setPassword('');
            setRepeatPassword('');
        }
    }

    return (
        <>
            <Typography
                variant='h2'
                fontWeight={400}
                marginBottom="24px"
            >
                Новый пароль
            </Typography>
            <TextField
                variant="outlined"
                placeholder="Придумайте новый пароль"
                sx={{
                    marginBottom: '32px',
                    borderRadius: '0',
                    width: '100%',
                    '& fieldset': {
                        borderRadius: '10px',
                        border: '1px solid #E8E9ED'
                    },
                }}
                type='password'
                value={password}
                onChange={e => setPassword(e.target.value)}
                autoComplete='new-password'
            />
            <TextField
                variant="outlined"
                placeholder="Подтвердите пароль"
                sx={{
                    marginBottom: '32px',
                    borderRadius: '0',
                    width: '100%',
                    '& fieldset': {
                        borderRadius: '10px',
                        border: '1px solid #E8E9ED'
                    },
                }}
                type='password'
                value={repeatPassword}
                onChange={e => setRepeatPassword(e.target.value)}
                autoComplete='new-password'
            />
            {compare && <Typography
                variant='subtitle2'
                sx={{
                    marginBottom: '24px',
                    color: '#FF3B30'
                }}
            >
                Пароли не совпадают
            </Typography>}
            {error && <Typography
                variant='subtitle2'
                sx={{
                    marginBottom: '24px',
                    color: '#FF3B30'
                }}
            >
                {(error as { status: number; data: string }).data}
            </Typography>}
            <Button sx={{
                borderRadius: '12px',
                textTransform: 'initial',
                width: '265px'
            }}
                variant='contained'
                onClick={runUpdatePassword}
                disabled={isLoading}
            >
                Войти
            </Button>
        </>
    );
});