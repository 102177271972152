import React from 'react';
import { PageHeader } from 'components';
import { Dialog } from '@mui/material';
import { DirectionsContent } from 'components/DirectionsContent';
import { CreateDirectionForm } from 'components/CreateDirectionForm';
import { useCreateTopicCategoryMutation } from 'core/services/topicsCategory';
export const Directions = () => {

    const [open, setOpen] = React.useState(false);
    const [signIn, { data, error, isLoading }] = useCreateTopicCategoryMutation()
    const handleClose = () => {
        setOpen(false);
    };
    const headerButtonClickHandler = () => {
        setOpen(true);
    };

    return (
        <>
            <PageHeader
                title="Проверка"
                description="Здесь находятся все тестирования для кандидатов, 
                созданные в вашем рабочем пространстве"
                buttonText="Создать направление"
                buttonOnClick={headerButtonClickHandler}
            />

            <>
                <DirectionsContent />
                <Dialog sx={{
                    '& div': {
                        overflow: 'hidden'
                    }
                }} open={open} onClose={handleClose}>
                    <CreateDirectionForm handleClose={handleClose} />
                </Dialog>
            </>
        </>
    );
};
