import { Grid } from '@mui/material';
import { styled } from '@mui/system';

export const ContentContainer = styled(Grid)(({ theme }) => ({
  height: '100%',
  overflow: 'auto',
  backgroundColor: theme.palette.background.lightGray,
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
}));
