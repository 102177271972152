import { styled } from '@mui/system';

// Компоненты стилей исключительно для формы авторизации
export const ContainerAuth = styled('div')({
    flexDirection: "column",
    padding: "60px 40px",
    display: "flex",
    borderRadius: "12px",
    maxWidth: "496px",
    width: "100%",
    height: "max-content",
    boxShadow: '0px 3px 21px rgba(35, 94, 231, 0.07)'
})
