import { PageHeader } from 'components';
import { DirectionContent } from 'components/DirectionContent';
import { useGetTopicsQuery } from 'core/services/topics';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const CertainDirection = function () {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const handleClose = () => {
        setOpen(false);
    };
    const headerButtonClickHandler = () => {
        navigate(`${pathname}/edit`)
    };
    return <>

        <PageHeader
            title='Новое направление'
            buttonText="Редактировать"
            buttonOnClick={headerButtonClickHandler}
            variant='outlined'
        />
       <DirectionContent />
    </>
}