import { TConfigAPI } from "./types";

export const host = '/';
// Список маршрутов - Backend Server
export const api: TConfigAPI = {
    routes: {
        signIn: '/api/users/login',
        recoveryPassword: '',
        sendCodeFromMail: '',
        updatePassword: '',
        logout: '/api/users/logout',
        getUsers: '/api/users/',
        createUser: '/api/users/',
        getTopicsCategory: '/api/topic_categories/',
        createTopicCategory: '/api/topic_categories/',
        updateTopicsCategory(topic_category_id: number){
            return `/api/topic_categories/${topic_category_id}`
        },
        deleteTopicsCategory(topic_category_id: number){
            return `/api/topic_categories/${topic_category_id}`
        },
        deleteUser(user_id: number){
            return `/api/users/${user_id}`;
        },
        getTests(status){
            if(status) return `/api/users-quizzes?status=${status}`;
            return '/api/users-quizzes';
        },
        getTest(user_quiz_id){
            return `/api/users-quizzes/${user_quiz_id}`;
        },
        getTopics(id: number){
            if(id) return `/api/topics/?topic_category_id=${id}`;
            return '/api/topics/' 
        },
        getOneTopics(id: number){
            if(id) return `/api/topics/${id}`;
            return '/api/topics/';
        },
        createTopic: '/api/topics'
    }
}