import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Layout, NoMatch } from 'components';
import { routes } from 'core/config/routes';
import { useAppSelector } from 'core/hooks/typed.hooks';
import { Auth } from 'pages/auth';
//@description Main Router
const AppRoutes: React.FC = function () {
  const isAuth = useAppSelector((state) => state.authReducer.isAuth);
  return (
    <Routes>
      {!isAuth ? (
        <>
          <Route path="/auth" element={<Auth />} />
          <Route path="*" element={<Navigate replace to="/auth" />} />
        </>
      ) : (
        <Route element={<Layout />}>
          {routes.map((route) => (
            <Route path={route.path} element={<route.element />} key={route.path} />
          ))}
          <Route path="*" element={<Navigate replace to="/test/directions" />} />
        </Route>
      )}
    </Routes>
  );
};
export { AppRoutes };
