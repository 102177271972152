import React, { ChangeEvent, FC } from 'react';
import { DialogContent, DialogActions, Button, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';
import ButtonBase from '@mui/material/ButtonBase';
import { useCreateTopicCategoryMutation } from 'core/services/topicsCategory';
import { FileUpload } from 'components/FileUpload';
interface ICreateDirectionForm {
    handleClose: () => void;
}

export const CreateDirectionForm: FC<ICreateDirectionForm> = ({ handleClose }) => {
    const [name, setName] = React.useState('');
    const [file, setFile] = React.useState<File>();
    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
          setFile(e.target.files[0]);
        }
      };
      const sendForm = async () => {
        if(!name && !file) return;
        const form = new FormData();
        form.append('name', name);
        form.append('image', file || '');
        form.append('description', 'dsadasda');
        form.append('company_id', '396');
        const res = await createTopicCategory(form).unwrap();
        if(res) handleClose();
      }
      const [createTopicCategory, {data, error, isLoading}] = useCreateTopicCategoryMutation();
      console.log(data);
    return (
        <div>

            <DialogContent sx={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <Typography variant='h2'>
                    Новое направление
                </Typography>
                <ButtonBase onClick={handleClose}>
                    <ClearIcon />
                </ButtonBase>
            </DialogContent>
            <DialogContent sx={{
                overflowY: 'initial'
            }}>
                <Typography variant='body1' sx={{
                    color: 'text.secondary'
                }}>Название</Typography>
                <TextField
                    variant="outlined"
                    placeholder='Введите название направления'
                    fullWidth
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
            </DialogContent>
            {/* @ts-ignore */}
            <FileUpload onChange={handleFileChange} />
            <DialogActions>
                <Button variant="outlined" onClick={handleClose}>
                    Отмена
                </Button>
                {/* {isError && <Typography color="error">Ошибка</Typography>} */}
                {/* <Button variant="contained" type="submit" disabled={isLoading} autoFocus>
                    Создать
                </Button> */}
                <Button onClick={sendForm} variant="contained" type="submit" autoFocus>
                    Создать
                </Button>
            </DialogActions>
        </div>
    );
};
