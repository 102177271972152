import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import { IFormSelectField } from 'core/models';

export const FormSelectField: FC<IFormSelectField> = ({
  control,
  name,
  required,
  label,
  placeholder,
  disabled,
  options,
  key,
  helperText,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      key={key}
      rules={{ required }}
      render={({ field }) => (
        <FormControl fullWidth>
          {label && (
            <InputLabel id={`select-helper-label-${name}`} disabled={disabled}>
              {label}
            </InputLabel>
          )}
          <Select
            {...field}
            {...(label ? { labelId: `select-helper-label-${name}` } : {})}
            placeholder={placeholder}
            disabled={disabled}
            label={label}>
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};
