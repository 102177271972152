import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Typography, Grid, IconButton, Button, Dialog } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate, useParams } from 'react-router-dom';
import { CircularLoading, FormContainer, FormFields } from 'components';
import { userEditField } from './fields';
import { userRoles } from 'core/constants';
import { IUser } from 'core/models';
import { useDeleteUserMutation } from 'core/services/users';
import { CreateForm } from 'components/CreateTopicForm';
import { DeleteUserForm } from 'components/DeleteUserForm';

export const UserEditForm: React.FC<{ data: IUser, isLoading: boolean }> = (props) => {
  const { data, isLoading } = props;
  const { userId, tab } = useParams() as any as {userId: number, tab: string};
  const { control, handleSubmit } = useForm({
    defaultValues: { ...data, role_id: userRoles[(data as IUser).role_id] },
  });
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const isEmployees = tab === 'employees' && userId;
  const onSubmit = () => {
    // TODO Добавить обработку формы изменения пользователя, пока нет на бэке
    console.log('Submit');
  };
  const isEditChangeHandle = () => {
    setIsEdit((prevState: boolean) => !prevState);
  };
  const [isShowModal, setIsShowModal] = useState(false);
  const handleClose = () => setIsShowModal(false);
  return (
    <FormContainer>
      <Grid container justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h2">Профиль</Typography>
        {isEdit ? (
          <Button variant="outlined">Сохранить</Button>
        ) : (
          <IconButton onClick={isEditChangeHandle}>
            <EditIcon />
          </IconButton>
        )}
      </Grid>
      {isLoading ? (
        <CircularLoading />
      ) : (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormFields fields={
              !isEmployees
                ?
                userEditField
                :
                userEditField.filter(user => user.name !== 'desired_position')
            } control={control} isAllDisabled={!isEdit} />
          </form>
          {
            isEdit && <>
              <Button>
                Сгенерировать новый пароль
              </Button>
              <Button onClick={() => setIsShowModal(true)} variant="outlined" color="error">
                Удалить профиль
              </Button>
            </>
          }
          <Dialog sx={{
              '& div': {
                  overflow: 'hidden'
              }
          }} open={isShowModal} onClose={handleClose}>
              <DeleteUserForm userId={userId} handleClose={handleClose} />
          </Dialog>
        
        </>
      )}
    </FormContainer>
  );
};
