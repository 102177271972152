import { FC } from 'react';
import { LogoLink } from './style';
import { Logo } from 'components';

interface ILinkedLogo {
  to: string;
}

export const LinkedLogo: FC<ILinkedLogo> = ({ to }) => {
  return (
    <LogoLink to={to}>
      <Logo />
    </LogoLink>
  );
};
