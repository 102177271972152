import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { host, api } from 'core/config/api';
import { ICreateUser, IUser, TUserResponse } from 'core/models/users.models';
// @task
// Настроить CORS

// API аутентификации
export const usersAPI = createApi({
    reducerPath: 'usersAPI',
    baseQuery: fetchBaseQuery({ baseUrl: host, credentials: 'include',}),
    tagTypes: ['Users'],
    endpoints: (build) => ({
        // Получение списка пользователей
        getUsers: build.query<TUserResponse, void>({
            query: () => {
                return ({
                    url: api.routes.getUsers,
                    method: 'GET',
                })
            },
            transformResponse: (response: { data: TUserResponse }) => response.data,
            providesTags: (result) =>result
                ?
                    [
                    ...result.map(({ id }) => ({ type: 'Users', id } as const)),
                    { type: 'Users', id: 'LIST' },
                    ]
                :
                    [{ type: 'Users', id: 'LIST' }],
                }),
        // Получение конкретного пользователя
        getUser: build.query<IUser, number | undefined>({
            query: (id) => {
                return ({
                    url: `${api.routes.getUsers}/${id}`,
                    method: 'GET',
                })
            },
            providesTags: (result, error, id) => {
                console.log(result, error, id)
                return [{ type: 'Users', id }]},
        }),
        // Создать нового пользователя
        createUser: build.mutation<TUserResponse, Partial<ICreateUser>>({
            query: (props) => {
                return ({
                    url: api.routes.getUsers,
                    method: 'POST',
                    body: props
                })
            },
            transformResponse: (response: { data: TUserResponse }) => response.data,
            invalidatesTags: [{ type: 'Users', id: 'LIST' }],
        }),
        deleteUser: build.mutation<TUserResponse, number>({
            query: (user_id) => {
                return ({
                    url: api.routes.deleteUser(user_id),
                    method: 'DELETE',
                })
            },
            invalidatesTags: [{ type: 'Users', id: 'LIST' }],
        }),
    }),
})
export const { useGetUsersQuery, useCreateUserMutation, useGetUserQuery, useDeleteUserMutation } = usersAPI;