import React, { FC } from 'react';
import { IFormFields } from 'core/models';
import { fieldsMap } from 'core/constants';

export const FormFields: FC<IFormFields> = ({ fields, isAllDisabled, control }) => {
  return (
    <>
      {fields.map((field) =>
        fieldsMap[field.fieldType]({ ...field, control, disabled: isAllDisabled, key: field.name }),
      )}
    </>
  );
};
