import { userRoles } from 'core/constants';
import { ITextField, ISelectField } from 'core/models';

export const userEditField: Array<ITextField | ISelectField> = [
  {
    fieldType: 'textField',
    name: 'last_name',
    required: true,
    label: 'Фамилия',
    placeholder: 'Новый кандидат',
  },
  {
    fieldType: 'textField',
    name: 'first_name',
    required: true,
    label: 'Имя',
    placeholder: 'Новый кандидат',
  },
  {
    fieldType: 'textField',
    name: 'role_id',
    required: true,
    label: 'Роль',
    placeholder: 'Новый кандидат',
  },
  {
    fieldType: 'textField',
    name: 'desired_position',
    required: true,
    label: 'Желаемая должность',
    placeholder: 'Новый кандидат',
  },
  {
    fieldType: 'textField',
    name: 'email',
    required: true,
    label: 'Почта',
    placeholder: 'Новый кандидат',
  },
];
export const curatorsField: Array<ITextField | ISelectField> = [
  {
    fieldType: 'textField',
    name: 'role_id',
    required: true,
    label: 'HR',
    placeholder: 'Новый кандидат',
  },
  {
    fieldType: 'textField',
    name: 'role_id',
    required: true,
    label: 'Проверяющие',
    placeholder: 'Новый кандидат',
  },
]