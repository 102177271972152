import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { FormControl, TextField } from '@mui/material';
import { IFormTextField } from 'core/models';

export const FormTextField: FC<IFormTextField> = ({
  control,
  name,
  required,
  label,
  placeholder,
  disabled,
  autoComplite,
  key,
  helperText,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      key={key}
      rules={{ required }}
      render={({ field }) => (
        <FormControl fullWidth>
          <TextField
            {...field}
            label={label}
            placeholder={placeholder}
            disabled={disabled}
            autoComplete={autoComplite}
            helperText={helperText}
          />
        </FormControl>
      )}
    />
  );
};
