import { styled } from '@mui/system';
import bg from '../../assets/img/bg.jpg';
// Компоненты стилей исключительно для страницы авторизации
export const LayoutAuth = styled('div')({
    gridTemplateColumns: '1fr 1 fr',
    display: 'flex',
    width: '100vw'
})
export const Background = styled('div')({
    backgroundImage: `url(${bg})`,
    width: '55%',
    height: '100vh',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex'
})
export const WrapperLogo = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: 'auto',
    width: 'max-content',
    height: 'max-content',
    gap: '40px',
    padding: '156px 100px',
    borderRadius: '12px',
    background: 'rgba(255, 255, 255, 0.1)',
})
export const WrapperAuth = styled('div')({
    margin: 'auto',
    maxWidth: '496px',
    width: '100%'
})