import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Box, Button } from '@mui/material';
import React from 'react';
interface IProps {
    buttonOnClick: () => void;
}
export const StatsForm: React.FC<IProps> = function ({ buttonOnClick }) {
    return <Grid sx={{
        backgroundColor: '#FFF',
        borderRadius: '0.625rem'
    }} width='484px' alignSelf='start'>
        <DialogContent sx={{
            gap: '1.5rem',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Typography variant='h2'>Статистика</Typography>
            <Box display='flex' justifyContent='space-between'>
                <Typography>Темы</Typography>
                <Typography>2</Typography>
            </Box>
            <Box paddingTop='1.25rem' borderTop='1px solid #E2E2E4' display='flex' justifyContent='space-between'>
                <Typography>Вопросы</Typography>
                <Typography>2</Typography>
            </Box>
            <Button onClick={buttonOnClick} sx={{
                width: 'max-content'
            }} variant='contained'>Новая тема +</Button>
        </DialogContent>
    </Grid>
}