import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { host, api } from 'core/config/api';
import { IUserCodeRecovery, IUserRecoveryPassword, IUserSignIn, IUserUpdatePassword, TAuthResponse } from 'core/models/auth.models';
// @task
// Настроить CORS

// API аутентификации
export const authAPI = createApi({
    reducerPath: 'authAPI',
    baseQuery: fetchBaseQuery({ baseUrl: host }),
    endpoints: (build) => ({
        // Авторизация пользователя
        signIn: build.mutation<TAuthResponse, IUserSignIn>({
            query: (user) => {
                return ({
                    url: api.routes.signIn,
                    method: 'POST',
                    body: user,
                    responseHandler: response => {
                        if(response.status !== 200) throw new Error('Ошибка при авторизации');
                        return response.json();
                    }
                });
            },
        }),
        // Восстановление пароля посредством отправки кода на Email пользователя
        recoveryPassword: build.mutation<any, IUserRecoveryPassword>({
            query: (data) => ({
                // Возвращаемый тип предварительно any. Эндпоинт для данного запроса не нашел.
                url: api.routes.recoveryPassword,
                method: 'POST',
                body: data,
            })
        }),
        // Отправить код на восстановление пароля на сервер для валидация
        sendCodeRecovery: build.mutation<any, IUserCodeRecovery>({
            query: (data) => ({
                // Возвращаемый тип предварительно any. Эндпоинт для данного запроса не нашел.
                url: api.routes.sendCodeFromMail,
                method: 'POST',
                body: data,
            })
        }),
        // Обновление пароля
        updatePassword: build.mutation<any, IUserUpdatePassword>({
            query: (data) => ({
                // Возвращаемый тип предварительно any. Эндпоинт для данного запроса не нашел.
                url: api.routes.updatePassword,
                method: 'POST',
                body: data,
            })
        }),
        // Выход из аккаунта
        logout: build.mutation<string, void>({
            query: () => ({
                url: api.routes.logout,
                method: 'POST',
                credentials: 'include',
                responseHandler: response => response.text()
            })
        })
    }),
})
export const
    {
        useSignInMutation,
        useRecoveryPasswordMutation,
        useSendCodeRecoveryMutation,
        useUpdatePasswordMutation,
        useLogoutMutation,
    } = authAPI;
