import React, { memo, useState } from "react";
import Cookie from 'js-cookie';
import { changeIsAuth, changeStateAuthForm } from "core/store/slices/auth";
import { useSignInMutation } from "core/services/auth";
import { useAppDispatch } from "core/hooks/typed.hooks";
import { EStateAuthForm } from "core/constants/auth.constants";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import TextField from '@mui/material/TextField'
import { Button } from "@mui/material";
import { IAuthError } from "core/models";
import { saveUser } from "core/store/slices/user/userSlice";

/**
 * @description Компонент формы авторизации пользователя
 */
export const AuthFormLogin: React.FC = memo(function () {
    /**
     * @description API авторизации пользователя
     */
    const dispatch = useAppDispatch();
    const [signIn, { data, error, isLoading }] = useSignInMutation()
    /**
     * @param {string} email email
     * @param {function} setEmail setEmail
     */
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const runSignIn = async function (e: React.MouseEvent) {
        try {
            const payload = await signIn({
                email,
                password
            }).unwrap();
            if (payload) {
                Cookie.set('session_user', 'auth', { expires: 30 });
                dispatch(changeIsAuth(true));
                setEmail('');
                setPassword('');
                // @ts-ignore
                dispatch(saveUser(payload));
            }


        } catch (error) {
            setEmail('')
            setPassword('')
        }
    }
    return (
        <>
            <Typography
                variant="h1"
                marginBottom="48px"
                fontWeight={600}
            >
                Авторизация
            </Typography>
            <TextField
                variant="outlined"
                placeholder="Email"
                sx={{
                    marginBottom: '32px',
                    borderRadius: '0',
                    width: '100%',
                    '& fieldset': {
                        borderRadius: '10px',
                        border: '1px solid #E8E9ED'
                    },
                }}
                type='text'
                value={email}
                onChange={e => setEmail(e.target.value)}
                autoComplete='new-password'
            />
            <TextField
                variant="outlined"
                placeholder="Пароль"
                sx={{
                    marginBottom: '32px',
                    borderRadius: '0',
                    width: '100%',
                    '& fieldset': {
                        borderRadius: '10px',
                        border: '1px solid #E8E9ED'
                    },
                }}
                type='password'
                value={password}
                onChange={e => setPassword(e.target.value)}
                autoComplete='new-password'
            />
            <Link
                onClick={() => dispatch(changeStateAuthForm(EStateAuthForm.RECOVERY))}
                marginBottom='24px'
                variant="body2"
                sx={{
                    textDecoration: 'none',
                    cursor: 'pointer'
                }}
                rel="noopener noreferrer"
                target="_blank"
            >
                Забыли пароль?
            </Link>
            {error && <Typography
                variant='subtitle2'
                sx={{
                    marginBottom: '24px',
                    color: '#FF3B30'
                }}
            >
                {(error as IAuthError).data.email[0]}
            </Typography>}
            <Button sx={{
                borderRadius: '12px',
                textTransform: 'initial',
                width: '265px'
            }}
                variant='contained'
                onClick={runSignIn}
                disabled={isLoading}
            >
                Войти
            </Button>
        </>
    );
});