import { ITextField, ISelectField } from 'core/models';

export const createFields: Array<ITextField | ISelectField> = [

    {
        fieldType: 'textField',
        name: 'name_topic',
        placeholder: 'Новая тема',
        required: true,
        autoComplite: 'off',
        label: 'Название',
        // @ts-ignore
        marginTop: '10px'
    },
    {
        fieldType: 'textField',
        name: 'description_topic',
        placeholder: 'Нет описания',
        required: true,
        autoComplite: 'off',
        label: 'Описание'
    },
];