import { userRoles } from 'core/constants';
import { ITextField, ISelectField } from 'core/models';

export const createUsersFields: Array<ITextField | ISelectField> = [
  {
    fieldType: 'select',
    name: 'role_id',
    required: true,
    label: 'Роль',
    placeholder: 'Выберите роль',
    helperText: 'Выбор роли вляет на функционал, доступный пользователю в системе',
    options: Object.keys(userRoles).map((role) => ({ value: +role + 1, label: userRoles[role] })),
  },
  {
    fieldType: 'textField',
    name: 'email',
    label: 'Почта',
    placeholder: 'company_name@company.ru',
    required: true,
    autoComplite: 'off',
    helperText: 'На указанную почту придет временный пароль для авторизации',
  },
];
