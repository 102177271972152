import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { host, api } from 'core/config/api';
import { ITest, ITests } from 'core/models/test.models';
// @task
// Настроить CORS

// API аутентификации
export const testingAPI = createApi({
    reducerPath: 'testingAPI',
    baseQuery: fetchBaseQuery({ baseUrl: host, credentials: "include" }),
    endpoints: (build) => ({
        // Авторизация пользователя
        getTests: build.query<ITests, 'assigned' | 'finished' | 'in_review' | undefined>({
            query: (status) => {
                // if(!status) throw new Error("Отсутствует Status в запросе!");
                return ({
                    url: api.routes.getTests(status),
                    method: 'GET',
                    responseHandler: response => {
                        return response.json();
                    }
                });
            },
        }),
        getTest: build.query<ITest, string | undefined>({
            query: (user_quiz_id) => {
                if(!user_quiz_id) throw new Error("Отсутствует ID в запросе!");
                return ({
                    url: api.routes.getTest(user_quiz_id),
                    method: 'GET',
                    responseHandler: response => {
                        return response.json();
                    }
                });
            },
        }),
    }),
})
export const
    {
        useGetTestsQuery,
        useGetTestQuery
    } = testingAPI;
