import React, { Fragment } from 'react';
import { List } from '@mui/material';
import { NavigatorItem } from 'components';
import { menuLinks } from 'core/config/menuLinks';

export const NavigatorMenu = () => {
  return (
    <List disablePadding>
      {menuLinks.map(({ id, title, icon, link }) => (
        <Fragment key={id}>
          <NavigatorItem text={title} link={link || id} icon={icon} />
        </Fragment>
      ))}
    </List>
  );
};
