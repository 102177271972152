import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { host, api } from 'core/config/api';
import { ITopic, ITopics } from 'core/models/topics.models';
// @task
// Настроить CORS

// API аутентификации
export const topicsAPI = createApi({
    reducerPath: 'topicsAPI',
    baseQuery: fetchBaseQuery({ baseUrl: host, credentials: "include" }),
    endpoints: (build) => ({
        // Авторизация пользователя
        getTopics: build.query<ITopics, number>({
            query: (id: number) => {
                return ({
                    url: api.routes.getTopics(id),
                    method: 'GET',
                    responseHandler: response => {
                        return response.json();
                    }
                });
            },
        }),
        getOneTopics: build.query<ITopic, number>({
            query: (id: number) => {
                return ({
                    url: api.routes.getOneTopics(id),
                    method: 'GET',
                    responseHandler: response => {
                        return response.json();
                    }
                });
            },
        }),
        // @ts-ignore
        createTopic: build.mutation<ITopicsCategoryGet, FormData>({
            query: (topic) => {
                return ({
                    url: api.routes.createTopic,
                    body: topic,
                    method: 'POST',
                    responseHandler: response => {
                        return response.json();
                    }
                });
            },
        }),
    }),
})
export const
    {
        useGetTopicsQuery,
        useGetOneTopicsQuery,
        useCreateTopicMutation
    } = topicsAPI;
