import React, { memo, useState } from "react";
import { changeStateAuthForm } from "core/store/slices/auth";
import { useRecoveryPasswordMutation } from "core/services/auth";
import { useAppDispatch } from "core/hooks/typed.hooks";
import { EStateAuthForm } from "core/constants/auth.constants";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";


// @description Форма отправки кода восстановления на Email пользователя
export const AuthFormResetPassword: React.FC = memo(function () {
    // @description API отправки кода на Email
    const dispatch = useAppDispatch();
    const [recoveryPassword, { error, isLoading }] = useRecoveryPasswordMutation();
    // @description State Component
    const [email, setEmail] = useState("");
    // @return {void}
    // @description Функция отправки кода на Email пользователя
    const runRecoveryPassword = async function () {
        try {
            const payload = await recoveryPassword({
                email
            }).unwrap();
            if (payload) {
                dispatch(changeStateAuthForm(EStateAuthForm.SEND_CODE_RECOVERY));
                setEmail('')

            }
        } catch (error) {
            console.error('rejected', error);
            setEmail('');
        }
    }
    return (
        <>
            <Typography
                variant='h2'
                fontWeight={400}
                marginBottom="24px"
            >
                Восстановление пароля
            </Typography>
            <Typography

                variant='subtitle2'
                sx={{
                    marginBottom: '24px',
                    color: '#929EAF'
                }}
            >
                Введите электронный адрес, который указывали при регистрации в системе.
                Мы пришлем на него ссылку для восстановления пароля
            </Typography>
            <TextField
                variant="outlined"
                placeholder="Email"
                sx={{
                    marginBottom: '32px',
                    borderRadius: '0',
                    width: '100%',
                    '& fieldset': {
                        borderRadius: '10px',
                        border: '1px solid #E8E9ED'
                    },
                }}
                type='text'
                value={email}
                onChange={e => setEmail(e.target.value)}
                autoComplete='new-password'
            />
            {error && <Typography
                variant='subtitle2'
                sx={{
                    marginBottom: '24px',
                    color: '#FF3B30'
                }}
            >
                {(error as { status: number; data: string }).data}
            </Typography>}
            <Button sx={{
                borderRadius: '12px',
                textTransform: 'initial',
                width: '265px',
                marginBottom: '25.5px'
            }}
                variant='contained'
                onClick={runRecoveryPassword}
                disabled={isLoading}
            >
                Продолжить
            </Button>
            <Link
                onClick={() => dispatch(changeStateAuthForm(EStateAuthForm.LOGIN))}
                variant="body2"
                sx={{
                    textDecoration: 'none',
                    cursor: 'pointer'
                }}
                rel="noopener noreferrer"
                target="_blank"
            >
                Я вспомнил пароль (кнопка назад)
            </Link>
        </>
    );
});