import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookie from 'js-cookie';
import { EStateAuthForm } from 'core/constants/auth.constants';
import { IAuthState } from './types';

const initialState: IAuthState = {
  isAuth: !!Cookie.get('session_user'),
  stateAuthForm: EStateAuthForm.LOGIN
}
// Редьюсер авторизации пользователя
export const authSlice = createSlice({
  initialState,
  name: 'authSlice',
  reducers: {
    // @param {IAuthState} state - состояние данного редьюсера
    // @param {boolean} action.payload - значение определяющее аутентификацию пользователя
    // Функция отвечающая за изменение состояния авторизации
    changeIsAuth: (state, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload;
    },
    // @param {IAuthState} state - состояние данного редьюсера
    // @param {EStateAuthForm} - Switch состояния окна авторизации
    // Функция позволяет отрисовывать необходимое состояние окна авторизации
    changeStateAuthForm: (state, action: PayloadAction<EStateAuthForm>) => {
      state.stateAuthForm = action.payload;
    }
  },
});
export default authSlice.reducer;
export const { changeIsAuth, changeStateAuthForm } = authSlice.actions;