import { useGetTestsQuery } from 'core/services/testing';
import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
type IOperand = '.' | '/' | '-' | ':';
type IVariant = 'reverse';
type IDate = Date | number | string;
type IParseDate = (date: IDate, operand: IOperand, variant?: IVariant) => string;
const parseDate: IParseDate = function (date, operand, variant) {
    let isDate = date;
    if (Number.isInteger(isDate)) isDate = new Date(isDate);
    const updateDate = Date.parse(isDate as string);
    const day = ('0' + new Date(updateDate).getDate());
    const month = ('0' + (new Date(updateDate).getMonth() + 1));
    const year = (new Date(updateDate).getFullYear());
    const updateDay = day.length === 3 ? day.slice(1) : day;
    const updateMonth = month.length === 3 ? month.slice(1) : month;
    if (variant === 'reverse') return `${year}${operand}${updateMonth}${operand}${updateDay}`;
    return `${updateDay}${operand}${updateMonth}${operand}${year}`;
}
const updateStatus = {
    in_review: {
        color: '#FF9500',
        name: 'Пройден',
    },
    finished: {
        color: '#E5E5E9',
        name: 'Проверен',
    },
    assigned: {
        color: '#235EE7',
        name: 'Создан',
    }
}

export const Testing = function () {
    const navigate = useNavigate();
    const [status, setStatus] = React.useState<'assigned' | 'finished' | 'in_review' | undefined>(undefined);
    const { data } = useGetTestsQuery(status);
    console.log(data);
    return <>
        {
            !data && <div>Нет пройденных тестирований на проверку</div>
        }
        {
            data && <Grid height={"inherit"} container>
                <Grid gap={4} container flexDirection={"row"}>
                    {data.data.map(test => <div style={{width: '100%'}} onClick={() => navigate(`/test/check/${test.id}`)} key={test.id}>
                        <Grid container padding={3} borderRadius={2} sx={{
                        backgroundColor: 'white',
                        cursor: 'pointer'
                    }}>

                        <Grid container flexDirection={"row"} alignItems={"center"} justifyContent={'space-between'}>
                            <Typography variant="h1">
                                Тест “{test.quiz_name}”<Typography display={'inline'} variant="h5">({test.questions_amount} вопросов)</Typography>{
                                    test.status === 'in_review' && <div style={{
                                        width: '15px',
                                        height: '15px',
                                        borderRadius: '100px',
                                        backgroundColor: 'red',
                                        display: "inline-block",
                                        marginLeft: '25px'
                                    }}></div>
                                }
                            </Typography>
                            <Typography color={"white"} sx={{
                                backgroundColor: updateStatus[test.status as 'assigned' | 'finished' | 'in_review'].color,
                                padding: '2px 8px',
                                borderRadius: '20px',
                                width: '88px',
                                textAlign: 'center'
                            }}>{updateStatus[test.status as 'assigned' | 'finished' | 'in_review'].name}</Typography>
                        </Grid>

                        <Typography variant='h6' color={'blue'}>{test.user_full_name}</Typography>
                        <Grid container flexDirection={"row"} gap={2}>
                            <Typography color={'gray'}>Пройден {parseDate(test.updated_at, '-')}</Typography>
                            <Typography color={'gray'}>{test.reviewer_id}{test.reviewer_full_name}</Typography>
                            <Typography color={'gray'}>Reviewer {test.reviewer_full_name}</Typography>
                        </Grid>
                    </Grid>
                    </div>)}
                </Grid>
            </Grid>
        }
        {
            data && <Grid alignSelf={"baseline"} marginLeft={"24px"} width={"484px"} padding={5} sx={{
                backgroundColor: 'white'
            }}>
                <Typography variant="h1">Статистика</Typography>
                <Grid margin={"20px 0"} width={"100%"} container alignItems={"center"} justifyContent={"space-between"}>
                    <Typography variant="h6">Ожидают прохождения</Typography>
                    <Typography>{data.amount_assigned}</Typography>
                </Grid>
                <Grid margin={"20px 0"} borderTop={"1px solid lightgray"} container alignItems={"center"} justifyContent={"space-between"}>
                    <Typography variant="h6">Ожидают проверки</Typography>
                    <Typography>{data.amount_in_review}</Typography>
                </Grid>
                <Grid margin={"20px 0"} borderTop={"1px solid lightgray"} container alignItems={"center"} justifyContent={"space-between"}>
                    <Typography variant="h6">Проверено</Typography>
                    <Typography>{data.amount_finished}</Typography>
                </Grid>
            </Grid>
        }
    </>
}