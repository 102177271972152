import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { DialogContent, DialogActions, Button, Typography, Box } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';
import { FileUpload } from 'components/FileUpload';
import ButtonBase from '@mui/material/ButtonBase';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeleteTopicCategoryMutation } from 'core/services/topicsCategory';
interface IProps {
    typeForm: 'direction' | 'topic'
}
// 
export const EditForm: React.FC<IProps> = function ({ typeForm }) {
    const { directionId } = useParams();
    const { control, handleSubmit } = useForm();
    const navigator = useNavigate();
    const { idTopic } = useParams();
    const checkTypeForm = idTopic !== undefined;
    const onSubmit = () => console.log('Handle Create Direction');
    const [deleteTopic, {status}] = useDeleteTopicCategoryMutation();
    const deleteTopicCategory = function(){
        deleteTopic(directionId as unknown as number).finally(() => navigator('/test/directions/'));
    }
    return <Box sx={{
        alignSelf: 'start',
        backgroundColor: '#FFF',
        borderRadius: '0.625rem',
        width: '738px'
    }}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent sx={{
                overflowY: 'initial'
            }}>
                <Typography variant='body1' sx={{
                    color: 'text.secondary'
                }}>{`${checkTypeForm ? 'Название темы' : 'Название направления'}`}</Typography>
                <TextField
                    variant="outlined"
                    placeholder={checkTypeForm ? 'MongoDB' : 'Web-направление'}
                    fullWidth
                />
            </DialogContent>
            {!checkTypeForm && <FileUpload label='Обложка направления' />}
            {checkTypeForm && <>
                <DialogContent sx={{
                    overflowY: 'initial'
                }}>
                    <Typography variant='body1' sx={{
                        color: 'text.secondary'
                    }}>Описание</Typography>
                    <TextField
                        variant="outlined"
                        placeholder='Все, что может пригодиться для проверки знаний кандидата'
                        fullWidth
                    />
                </DialogContent>
            </>}
            <DialogActions>
                <Button onClick={deleteTopicCategory} variant="text" color='error'>
                    {checkTypeForm ? 'Удалить тему' : 'Удалить направление'}
                </Button>
            </DialogActions>
        </form>
    </Box>
}