import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Grid,
  Pagination
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useGetUsersQuery } from 'core/services/users';
import { getRoleName } from 'core/utils';
import { useNavigate } from 'react-router-dom';

export const Candidate = () => {
  const navigate = useNavigate();
  const { data } = useGetUsersQuery();

  const rowClickHandler = (userId: number) => {
    navigate(`${userId}`, { replace: true });
  };
console.log(data);
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Фамилия Имя</TableCell>
            <TableCell>Куратор</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Роль</TableCell>
            <TableCell>Создан</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {data ? (
            data.map(({ first_name, last_name, id, email, role_id }) => (
              <TableRow
                key={id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                onClick={() => rowClickHandler(id)}>
                <TableCell>{`${last_name} ${first_name}`}</TableCell>
                <TableCell>Нет данных</TableCell>
                <TableCell>{email}</TableCell>
                <TableCell>{getRoleName(role_id)}</TableCell>
                <TableCell>Нет данных</TableCell>
              </TableRow>
            ))
          ) : (
            <Typography>Нет данных</Typography>
          )}
          
        </TableBody>
      </Table>
      <div style={{width: '100%', backgroundColor: '#E5E5E9', borderRadius: '2px 2px 8px 8px'}}>
          <TextField
            variant="outlined"
            id="outlined-start-adornment"
            sx={{ m: 1, width: '25ch', backgroundColor: '#FFFFFF', borderRadius: '10%' }}
            placeholder='Найти кандидата...'
            InputProps={{
              startAdornment: <SearchIcon />,
            }}
            fullWidth
          />
      </div>
      <Grid container justifyContent="flex-end" marginTop={5}>
          <Pagination color="standard" count={10} variant="outlined" shape="rounded" />
      </Grid>
    </TableContainer>
  );
};
