import React from 'react';
import { CircularLoading, ContentContainer, PageHeader, User } from 'components';
import { useGetUserQuery } from 'core/services/users';
import { useParams } from 'react-router-dom';

export const UserPage = () => {
  const { userId, tab } = useParams();
  const { data, isLoading } = useGetUserQuery(+(userId || ''));
  console.log(data);
  const isCandidate = tab === 'candidate' && userId;
  if (isLoading) {
    return <CircularLoading />;
  }
  return (
    <>
      <PageHeader
        title={
          data && (data.first_name || data.last_name)
            ? `${data.last_name} ${data.first_name}`
            : 'Новый кандидат'
        }
        backLink={`/users/${tab}`}
        buttonText={isCandidate ? "Новый тест" : ''}
      />
      <ContentContainer>
        <User />
      </ContentContainer>
    </>
  );
};
