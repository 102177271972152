import { ITextField, ISelectField } from 'core/models';

export const createFields: Array<ITextField | ISelectField> = [

    {
        fieldType: 'textField',
        name: 'name_topic',
        placeholder: 'Введите название темы',
        required: true,
        autoComplite: 'off',
        label: 'Название'
    },
    {
        fieldType: 'textField',
        name: 'description_topic',
        placeholder: 'Введите описание темы',
        required: true,
        autoComplite: 'off',
        label: 'Описание'
    },
];