import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { host, api } from 'core/config/api';
import { IUserCodeRecovery, IUserRecoveryPassword, IUserSignIn, IUserUpdatePassword, TAuthResponse } from 'core/models/auth.models';
import { ITopicsCategoryCreate, ITopicsCategoryGet } from 'core/models/topicsCategory.models';
// @task
// Настроить CORS

// API аутентификации
export const topicsCategoryAPI = createApi({
    reducerPath: 'topicsCategoryAPI',
    baseQuery: fetchBaseQuery({ baseUrl: host, credentials: "include" }),
    tagTypes: ['Topics'],
    endpoints: (build) => ({
        // Авторизация пользователя
        getTopicsCategory: build.query<ITopicsCategoryGet, void>({
            query: () => {
                return ({
                    url: api.routes.getTopicsCategory,
                    method: 'GET',
                    responseHandler: response => {
                        return response.json();
                    }
                });
            },
            
        }),
        createTopicCategory: build.mutation<ITopicsCategoryGet, FormData>({
            query: (topicCategory) => {
                return ({
                    url: api.routes.createTopicCategory,
                    body: topicCategory,
                    method: 'POST',
                    responseHandler: response => {
                        return response.json();
                    }
                });
            },
            invalidatesTags: [{ type: 'Topics', id: 'LIST' }],
        }),
        updateTopicCategory: build.mutation<ITopicsCategoryGet, {formData: FormData, id: number}>({
            query: ({formData, id}) => {
                return ({
                    url: api.routes.updateTopicsCategory(id),
                    body: formData,
                    method: 'PATCH',
                    responseHandler: response => {
                        return response.json();
                    }
                });
            },
            invalidatesTags: [{ type: 'Topics', id: 'LIST' }],
        }),
        deleteTopicCategory: build.mutation<ITopicsCategoryGet, number>({
            query: (id) => {
                return ({
                    url: api.routes.deleteTopicsCategory(id),
                    method: 'DELETE',
                    responseHandler: response => {
                        return response.json();
                    }
                });
            },
            invalidatesTags: [{ type: 'Topics', id: 'LIST' }],
        }),
    }),
})
export const
    {
        useGetTopicsCategoryQuery,
        useCreateTopicCategoryMutation,
        useUpdateTopicCategoryMutation,
        useDeleteTopicCategoryMutation
    } = topicsCategoryAPI;
