import React, { FC, PropsWithChildren } from 'react';
import { Toolbar } from '@mui/material';
import { ContentContainer } from 'components';

interface ITabPanel {
  value?: string;
  height?: string;
  id: string;

}

export const TabPanel: FC<PropsWithChildren<ITabPanel>> = ({ value, id, children, height }) => {
  return (
    <ContentContainer hidden={value !== id}>
      <Toolbar sx={{
        height,
        justifyContent: 'center'
      }} >{value === id && children}</Toolbar>
    </ContentContainer>
  );
};
