// Switch Consts для формы авторизации
export enum EStateAuthForm {
    LOGIN = 'LOGIN',
    RECOVERY = 'RECOVERY',
    SEND_CODE_RECOVERY = 'SEND_CODE_RECOVERY',
    UPDATE_PASSWORD = 'UPDATE_PASSWORD'
}

export const userRoles: Record<number | string, string> = {
    0: "Кандидат",
    1: "HR",
    2: "Админ",
    3: "Владелец компании",
    4: "Супер админ",
}