import React from 'react';
import Cookie from 'js-cookie';
import { Box, Typography } from '@mui/material';
import { Grid, Divider } from '@mui/material';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { LinkedLogo, NavigatorItem, NavigatorMenu } from 'components';
import { NavigatorWrapper } from './style';
import { useDispatch } from 'react-redux';
import { useLogoutMutation } from 'core/services/auth';
import { changeIsAuth } from 'core/store/slices/auth';

export const Navigator = () => {
  const dispatch = useDispatch();
  const [logout, { }] = useLogoutMutation();
  const logoutHandle = async () => {
    try {
      const payload = await logout();
      if (payload) {
        console.log('successful', payload);
        Cookie.remove('session_user');
        dispatch(changeIsAuth(false));
      }


    } catch (error) {
      console.error('rejected', error);
    }
  };
  return (
    <NavigatorWrapper>
      <Grid>
        <LinkedLogo to="/" />
        <Divider light />
      </Grid>
      <Grid px={1.5} overflow="auto">
        <Typography py={2} mb={1.5} color="white" variant="subtitle1">
          IRLIX Company
        </Typography>
        <NavigatorMenu />
      </Grid>
      <Grid>
        <Divider light />
        <Box px={1.5}>
          <NavigatorItem text="Выйти" icon={<LogoutOutlinedIcon />} onClick={logoutHandle} />
        </Box>
      </Grid>
    </NavigatorWrapper>
  );
};
