import { useState } from 'react';
export const useCompare = function () {
    const [compare, setCompare] = useState(false);
    const comparator = function (...args: Array<string>): boolean {
        for (let i = 0; i < args.length; i++) {
            for (let j = i + 1; j < args.length; j++) {
                if (args[i] !== args[j]) {
                    setCompare(true);
                    return false;
                }
            }
        }
        return true;
    }
    return [compare, comparator] as const;
}