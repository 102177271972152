import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from 'core/models';
import { IInitialState } from './types';

const initialState: IInitialState = {
    user: {
        companies: [],
        email: '',
        first_name: '',
        id: 0,
        last_name: '',
        middle_name: '',
        quizzes: [],
        role_id: 0,
        curators: ''
    }
}
// Редьюсер авторизации пользователя
export const userSlice = createSlice({
  initialState,
  name: 'userSlice',
  reducers: {
    // @param {IAuthState} state - состояние данного редьюсера
    // @param {boolean} action.payload - значение определяющее аутентификацию пользователя
    // Функция отвечающая за изменение состояния авторизации
    saveUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
  },
});
export default userSlice.reducer;
export const { saveUser } = userSlice.actions;