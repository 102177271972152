import React, { FC } from 'react';
import { DialogContent, DialogActions, Button, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import ButtonBase from '@mui/material/ButtonBase';
import DialogTitle from '@mui/material/DialogTitle';
import { useDeleteUserMutation } from 'core/services/users';
import { useNavigate } from 'react-router-dom';
interface IDeleteUserForm {
    handleClose: () => void;
    userId: number;
}

export const DeleteUserForm: FC<IDeleteUserForm> = ({ handleClose, userId }) => {
    const navigate = useNavigate();
    const [deleteUser] = useDeleteUserMutation();
    const runDeleteUser = async function () {
        try {
            if (!userId) return;
            const payload = await deleteUser(userId).unwrap();
            navigate(-1);
            handleClose();
        } catch (error) {
            console.error('rejected', error);
        }
    }
    return (
        <>
            <DialogTitle display='flex' justifyContent='space-between'>
                Удаление профиля
                <ButtonBase onClick={handleClose}>
                    <ClearIcon />
                </ButtonBase></DialogTitle>
            <DialogContent>
                <Typography>
                    Профиль участника будет удален без возможности восстановления.
                    Продолжить?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleClose}>
                    Отмена
                </Button>
                <Button onClick={runDeleteUser} variant="contained" color="error">
                    Удалить
                </Button>
            </DialogActions>
        </>
    );
};