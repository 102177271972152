import { combineReducers, configureStore } from '@reduxjs/toolkit'


import { authAPI } from 'core/services/auth';
import { usersAPI } from 'core/services/users';
import authReducer from './slices/auth/authSlice';
import userReducer from './slices/user/userSlice';
import { topicsCategoryAPI } from 'core/services/topicsCategory';
import { testingAPI } from 'core/services/testing';
import { topicsAPI } from 'core/services/topics';
const rootReducer = combineReducers({
  authReducer,
  userReducer,
  [authAPI.reducerPath]: authAPI.reducer,
  [usersAPI.reducerPath]: usersAPI.reducer,
  [topicsCategoryAPI.reducerPath]: topicsCategoryAPI.reducer,
  [testingAPI.reducerPath]: testingAPI.reducer,
  [topicsAPI.reducerPath]: topicsAPI.reducer
})



export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([authAPI.middleware, usersAPI.middleware, topicsCategoryAPI.middleware, testingAPI.middleware, topicsAPI.middleware]),

});

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = typeof store;
export type AppDispatch = AppStore['dispatch'];